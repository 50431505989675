import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { getRoute } from '../utils/utils'

import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import useAPI from '../utils/useAPI'

import ModalPayment from '../components/prompt/Payment'
import { useOutgoingInvoices } from '../views/sales/outgoingInvoices/useOutgoingInvoices'
import { useIncomingInvoices } from '../views/purchases/incomingInvoices/useIncomingInvoices'

export const mixinPayments = {
  components: {
    ModalPayment
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const paymentModalShow = ref(false)
    const paymentModalTitle = ref(i18n.t('NewPayment'))
    const currentPayment = ref(null)
    const sumOwed = ref(null)
    const submitPaymentFormRef = ref(null)

    const parentType = ref(null)
    const parentObject = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(paymentModalShow, () => {
      if (paymentModalShow.value == false) {
        resetPayment()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedOutgoingInvoice } = useOutgoingInvoices()
    const { submitValidatedIncomingInvoice } = useIncomingInvoices()

    const addPayment = (parentTypeLocal, parentObjectLocal) => {
      parentType.value = parentTypeLocal
      // parentObject.value = parentObjectLocal

      // console.log(parentTypeLocal)
      // console.log(parentObjectLocal)

      if (parentTypeLocal == 'outgoingInvoice') {
        let outgoingInvoice = store.getters['outgoingInvoice/getOutgoingInvoice'](parentObjectLocal.id)
        parentObject.value = outgoingInvoice
        currentPayment.value.wording = parentObjectLocal.billNumber
        currentPayment.value.credit = parseFloat(parseFloat(parentObjectLocal._balance).toFixed(2))
        currentPayment.value._outgoingInvoice = outgoingInvoice.id

        sumOwed.value = parentObjectLocal._balance

        paymentModalShow.value = true

      } else if (parentTypeLocal == 'incomingInvoice') {
        let incomingInvoice = store.getters['incomingInvoice/getEmptyIncomingInvoice']
        if ('id' in parentObjectLocal) {
          incomingInvoice = store.getters['incomingInvoice/getIncomingInvoice'](parentObjectLocal.id)
        }

        parentObject.value = incomingInvoice

        currentPayment.value.wording = parentObjectLocal.billNumber
        if ('id' in parentObjectLocal) {
          currentPayment.value.debit = parseFloat(parseFloat(parentObjectLocal._balance).toFixed(2))
          currentPayment.value._incomingInvoice = incomingInvoice.id
        } else {
          currentPayment.value.debit = parseFloat(parseFloat(parentObjectLocal.totalAmount).toFixed(2))
          currentPayment.value._incomingInvoice = null
          // currentPayment.value._incomingInvoice = 0
        }

        paymentModalShow.value = true

      }
    }

    const selectPayment = (parentTypeLocal, parentObjectLocal, payment) => {
      console.log(parentTypeLocal)
      console.log(parentObjectLocal)
      console.log(payment)
      parentType.value = parentTypeLocal
      parentObject.value = parentObjectLocal
      paymentModalTitle.value = i18n.t('EditPayment')
      currentPayment.value = JSON.parse(JSON.stringify(payment))

      paymentModalShow.value = true
    }

    const tryClosePaymentModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitPaymentFormRef.value.click()
      }
    }

    const submitValidatedPaymentLocal = () => {
      if (parentType.value == 'outgoingInvoice') {
        if ('id' in currentPayment.value) {
          if (parentType.value == 'outgoingInvoice') {
            let index = parentObject.value.bankOperations.findIndex(bo => bo.id == currentPayment.value.id)
            parentObject.value.bankOperations.splice(index, 1, JSON.parse(JSON.stringify(currentPayment.value)))

            submitValidatedOutgoingInvoice(parentObject.value)
              .then((response) => {
                if (getRoute().name == 'Workflow view') {
                  let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
                  let index = workflow.outgoingInvoices.findIndex(o => o.id == response.data.id)
                  workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
                }

                paymentModalShow.value = false
                resetPayment()
              })
          }
        } else {
          console.log(currentPayment.value)
          console.log(parentObject.value)

          parentObject.value.bankOperations.push(currentPayment.value)
          submitValidatedOutgoingInvoice(parentObject.value)
            .then((response) => {
              if (getRoute().name == 'Workflow view') {
                let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
                let index = workflow.outgoingInvoices.findIndex(o => o.id == response.data.id)
                workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
              }
              paymentModalShow.value = false
              resetPayment()
            })
        }
      } else if (parentType.value == 'incomingInvoice') {
        if ('id' in currentPayment.value) {

        } else {
          parentObject.value.bankOperations.push(currentPayment.value)
          submitValidatedIncomingInvoice(parentObject.value)
            .then(() => {
              resetPayment()
              paymentModalShow.value = false
            })
        }
      }
    }

    const removePaymentLocal = (parentTypeLocal, parentObjectLocal, payment) => {
      if (parentTypeLocal == 'outgoingInvoice') {
        // console.log(parentObjectLocal)
        let index = parentObjectLocal.bankOperations.findIndex(bo => bo.id == payment.id)
        parentObjectLocal.bankOperations.splice(index, 1)

        // parentObjectLocal = parentObjectLocal.bankOperations.filter(bo => bo.id != payment.id)
        // console.log(parentObjectLocal)
        submitValidatedOutgoingInvoice(parentObjectLocal)
      }
    }

    const resetPayment = () => {
      currentPayment.value = {
        operationDate: (new Date()).toISOString().slice(0, 10),
        bankTransactionDate: (new Date()).toISOString().slice(0, 10),
      }
      parentType.value = null
      parentObject.value = null
      sumOwed.value = null
    }
    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetPayment()
    // if (store.getters['paymentMethod/getPaymentMethods'].length == 0) {
    //   fetchPaymentMethods()
    // }
    // if (store.getters['bankAccount/getBankAccounts'].length == 0) {
    //   fetchBankAccounts()
    // }

    return {
      // Components
      capitalize,
      currency,

      // Data
      paymentModalShow,
      paymentModalTitle,
      currentPayment,
      submitPaymentFormRef,
      parentType,
      parentObject,
      sumOwed,

      // Computed

      // Methods
      addPayment,
      selectPayment,
      tryClosePaymentModal,
      submitValidatedPaymentLocal,
      removePaymentLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deletePaymentAlert (parentTypeLocal, parentObjectLocal, payment) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('thePayment') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            // this.parentType = parentTypeLocal
            // this.parentObject = parentObjectLocal
            this.removePaymentLocal(parentTypeLocal, parentObjectLocal, payment)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}