<template>
  <div>
    <search-filter
        :items="supplierReceivable"
        :isActionColumnEnabled="true"
        itemView="list"
        :sortOptions="sortOptions"
        :columns="columns"
        :show-past-years-filter="true"
        :allowAdd="false"
        @columnClick="addPayment('incomingInvoice', $event)"
    >
      <!--  =========================== -->
      <!--  ===    Results infos    === -->
      <!--  =========================== -->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getPreTaxAmounts(items)) }} HT / {{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <card-incoming-invoice
            :incoming-invoice="item"
            :isPayable="true"
            @click="addPayment('incomingInvoice', item)"
            @pay="addPayment('incomingInvoice', item)"
        />
      </template>


      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Column payment status-->
      <template #listView_head_paymentStatus="{item}">
        <icon icon="cash-register"/>
      </template>
      <template #listView_cell_paymentStatus="{item}">
        <b-badge
            v-if="item._paymentStatus != null"
            pill
            :variant="displayPaymentStatus(item._paymentStatus).variant"
        >
          {{ displayPaymentStatus(item._paymentStatus).value }}
        </b-badge>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Download-->
          <button-download
              @click.native.stop="downloadIncomingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              @click.native.stop="addPayment('incomingInvoice', item)"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>

    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :sumOwed="sumOwed"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinPayments } from '../../../mixins/mixinPayments'

import mixinSupplierReceivable from './mixinSupplierReceivable'

export default {
  components: {},
  mixins: [mixinSupplierReceivable, mixinPayments],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>