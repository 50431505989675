<template>
  <div>
    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100"
    >
      <v-select
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="list"
          :reduce="label => label.id"
          :placeholder="$tc('ChooseSalary')"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          :append-to-body="appendToBody"
          :filter="filterSalary"
          class="w-100"
      >
        <template v-slot:option="option">
          <div class="d-flex">
            <div class="w-100 text-truncate">
              {{ option.employee._display }}
            </div>
            <div class="text-right text-danger">
              {{ currency(option.net) }}
            </div>
          </div>
          <div class="d-flex font-italic">
            <div>
              <small>{{ capitalizeDate(option.month) }}</small>
            </div>
          </div>
        </template>

      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { comparableString } from '../../../utils/utils'

import vSelect from 'vue-select'
import store from '../../../store'
import moment from 'moment'

export default {
  components: {
    vSelect
  },
  props: {
    model: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onlyNotCompletelyPaid: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const name = ref('salary')
    const label = ref('_display')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const list = computed(() => {
      if (props.onlyNotCompletelyPaid) {
        return store.getters['salary/salariesNotCompletelyPaid']
      } else {
        return store.getters['salary/getSalaries']
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filterSalary = (options, search) => {
      return options.filter(s => {
        return (
            compare(comparableString(s.employee._display), comparableString(search)) ||
            compare(comparableString(s.net), comparableString(search)) ||
            compare(comparableString(moment(s.month).format('MMMM YYYY')), comparableString(search))
        )
      })
    }

    const compare = (label, search) => {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    }

    const capitalizeDate = (date) => {
      return capitalize(moment(date).format('MMMM YYYY'))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      localModel,
      name,
      label,

      // Computed
      list,

      // Methods
      filterSalary,
      capitalizeDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>