<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="sm"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryClosePaymentModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedPaymentLocal)">
        <select-bank-operation
            v-if="isSelectForm"
            :model.sync="paymentLocal"
            :bankAccounts="bankAccounts"
        />

        <form-payment
            v-else
            :bankOperation.sync="paymentLocal"
            :sumOwed="sumOwed"
            :showBankAffiliation="showBankAffiliation"
            :absoluteDisplay="absoluteDisplay"
            :formErrors="errors"/>
        <button ref="submitPaymentFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'
import { isObject } from '../../utils/utils'

import useAPI from '../../utils/useAPI'
import SelectBankOperation from '../input/custom/SelectBankOperation'
import FormPayment from '../form/BankOperation'
import store from '../../store'

export default {
  components: {
    ValidationObserver,
    SelectBankOperation,
    FormPayment
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    showBankAffiliation: {
      type: Boolean,
      default: false
    },
    sumOwed: {
      default: null
    },
    absoluteDisplay: {
      type: Boolean,
      default: false
    },
    isSelectForm: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const paymentLocal = ref(props.payment)
    const submitPaymentFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint, bankAccounts } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // const list = computed(() => {
    //   let bankOperations = []
    //   bankAccounts.value.forEach(ba => {
    //     ba.bankOperations.forEach(bo => {
    //       if (bo._isAttached == false) {
    //         bankOperations.push(bo)
    //       }
    //     })
    //   })
    //
    //   bankOperations.sort((a, b) => {return new Date(b.operationDate) - new Date(a.operationDate)})
    //
    //   return bankOperations
    // })

    // const detailledBo = computed( () => {
    //   let output = null
    //   console.log(paymentLocal.value)
    //
    //   bankAccounts.value.forEach(ba => {
    //     console.log(ba)
    //     ba.bankOperations.forEach(bo => {
    //       console.log(bo.id, paymentLocal.value)
    //       if (bo.id == paymentLocal.value) {
    //         output = bo
    //         // return bo
    //       }
    //     })
    //   })
    //
    //   return output
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(paymentLocal, (val) => {
      if (isObject(val)) {
        emit('update:payment', val)
      } else {
        emit('update:payment', JSON.parse(JSON.stringify(getBankOperationDetail(val))))
      }

      // emit('update:payment', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryClosePaymentModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitPaymentFormRef.value.click()
      }
    }

    const submitValidatedPaymentLocal = () => {
      emit('submitValidatedPayment')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedPaymentLocal()
      }
    }

    const handleCancel = () => {}

    const getBankOperationDetail = (boId) => {
      let output = null
      bankAccounts.value.forEach(ba => {
        ba.bankOperations.forEach(bo => {
          if (bo.id == boId) {
            output = bo
          }
        })
      })

      return output
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      paymentLocal,
      submitPaymentFormRef,
      observerRef,

      // Computed
      hideFooter,
      bankAccounts,

      // Methods
      tryClosePaymentModal,
      submitValidatedPaymentLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    payment: function (val) {
      this.paymentLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.modal-body {
  background-color: #f8f8f8;
}
</style>