import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { displayPaymentStatus } from '../../../utils/utils'
import { useIncomingInvoices } from '../../purchases/incomingInvoices/useIncomingInvoices'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import i18n from '../../../libs/i18n'
import SearchFilter from '../../../components/searchFilterV2/SearchFilter'
import CardIncomingInvoice from '@/components/card/IncomingInvoice'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonPayment from '@/components/button/Payment'
import moment from 'moment'

export default {
  components: {
    SearchFilter,
    CardIncomingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonPayment,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sortOptions = ref([
      {
        text: capitalize(i18n.tc('paymentByBankTransfert')),
        key: '_filter_isBankTransfertPayment',
      },
      {
        text: capitalize(i18n.t('paymentByDirectDebit')),
        key: '_filter_isDirectDebitPayment',
      },
      {
        text: capitalize(i18n.t('paymentByCheck')),
        key: '_filter_isCheckPayment',
      },
    ])

    const columns = ref([
      {
        display: '#',
        key: 'billNumber',
        sortable: true,
      },
      {
        display: capitalize(i18n.t('paymentDate')),
        key: 'paymentDate',
        isDate: true,
        sortable: true,
      },
      {
        display: capitalize(i18n.t('paymentStatus')),
        key: 'paymentStatus',
        sortable: true
      },
      {
        display: capitalize(i18n.t('supplier')),
        key: 'supplierCompany',
        sortable: true
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('preTaxAmount')),
        key: 'preTaxAmount',
        sortable: true
      },
      {
        display: capitalize(i18n.t('remaining')),
        key: '_sumOwed',
        sortable: true
      },
    ])



    // const additionalSortOptions = ref([])
    // const tableColumns = ref([
    //   { key: 'billNumber', label: '#', sortable: true },
    //   { key: 'paymentDate', label: i18n.t('paymentDate'), sortable: true },
    //   { key: 'paymentStatus', label: i18n.t('paymentStatus'), sortable: false },
    //   { key: 'contact', label: i18n.t('contact'), sortable: true },
    //   { key: 'title', label: i18n.t('title'), sortable: false },
    //   { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
    //   { key: 'sumOwed', label: i18n.t('remaining'), sortable: true },
    // ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const supplierReceivable = computed(() => {
      return store.getters['incomingInvoice/supplierReceivable']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(supplierReceivable, () => {
    //   enableFilters()
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchIncomingInvoices, fetchPaymentMethods, fetchBankAccounts } = useAPI()
    const {
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceStatus,
      downloadIncomingInvoice,
    } = useIncomingInvoices()

    const getPreTaxAmounts = (incomingInvoices) => {
      let preTaxAmount = 0
      incomingInvoices.forEach(oi => preTaxAmount += parseFloat(oi.preTaxAmount))

      return preTaxAmount
    }

    const getTotalAmounts = (incomingInvoices) => {
      let totalAmount = 0
      incomingInvoices.forEach(oi => totalAmount += parseFloat(oi.totalAmount))

      return totalAmount
    }

    // const enableFilters = () => {
    //   if (supplierReceivable.value.length) {
    //     supplierReceivable.value[0]._filters.forEach(filter => {
    //       if (
    //         filter.text.includes("paymentBy") &&
    //         additionalSortOptions.value.some(el => el.key == filter.key) == false
    //       ) {
    //         additionalSortOptions.value.push(filter)
    //       }
    //     })
    //   }
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchIncomingInvoices()
    fetchPaymentMethods()
    fetchBankAccounts()

    return {
      // Components
      capitalize,
      currency,
      displayPaymentStatus,
      moment,

      // Data
      sortOptions,
      columns,
      // additionalSortOptions,
      // tableColumns,

      // Computed
      supplierReceivable,

      // Methods
      getIncomingInvoiceIconStatus,
      getIncomingInvoiceStatus,
      downloadIncomingInvoice,
      getPreTaxAmounts,
      getTotalAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}