<template>
  <div>
    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100"
    >
      <v-select
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="list"
          :reduce="label => label.id"
          :placeholder="$tc('ChooseInvoice')"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          :append-to-body="appendToBody"
          :filter="filterOutgoingInvoice"
          class="w-100"
      >
        <template v-slot:option="option">
          <div class="d-flex">
            <div class="w-100 text-truncate">
              {{ option.billNumber }}
            </div>
            <div class="text-right text-success">
              {{ currency(option.totalAmount) }}
            </div>
          </div>
          <div class="d-flex font-italic">
            <div>
              <small>{{ option.billingDate|moment('L') }}</small>
            </div>
            <div class="w-100 text-right text-truncate">
              <small v-if="option.customerCompany != null">{{ option.customerCompany._display }}</small>
              <span v-else>{{ option.customerIndividual._display }}</span>
            </div>
          </div>
        </template>

      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { comparableString, getObjectId } from '../../../utils/utils'

import vSelect from 'vue-select'
import store from '../../../store'
import moment from 'moment'

export default {
  components: {
    vSelect
  },
  props: {
    model: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onlyNotCompletelyPaid: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    company: {
      type: Object
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const localCompany = ref(props.company)
    const name = ref('Sales')
    const label = ref('_display')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const companyId = computed(() => {
      return getObjectId(localCompany.value)
    })

    const list = computed(() => {
      if (props.onlyNotCompletelyPaid) {
        if (companyId.value != 0) {
          return store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid'].filter(oi => {
            return oi.customerCompany != null && oi.customerCompany.id == companyId.value
          })
        } else {
          return store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid']
        }
      } else {
        if (companyId.value != 0) {
          return store.getters['outgoingInvoice/getOutgoingInvoices'].filter(oi => {
            return oi.customerCompany != null && oi.customerCompany.id == companyId.value
          })
        } else {
          return store.getters['outgoingInvoice/getOutgoingInvoices']
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filterOutgoingInvoice = (options, search) => {
      return options.filter(ii => {
        return (
            (
                ii.billNumber != null &&
                compare(comparableString(ii.billNumber), comparableString(search))
            ) ||
            compare(comparableString(ii.totalAmount), comparableString(search)) ||
            compare(comparableString(moment(ii.billingDate).format('L')), comparableString(search)) ||
            (
                ii.customerCompany != null &&
                compare(comparableString(ii.customerCompany._display), comparableString(search))
            ) ||
            (
                ii.customerIndividual != null &&
                compare(comparableString(ii.customerIndividual._display), comparableString(search))
            )
        )
      })
    }

    const compare = (label, search) => {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    }

    const companyUpdated = (newCompany) => {
      localCompany.value = newCompany
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      localModel,
      name,
      label,

      // Computed
      list,

      // Methods
      filterOutgoingInvoice,
      companyUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>