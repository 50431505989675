<template>
  <div>
    <!-- Payment form-->
    <b-row v-if="containerSize=='sm'">

      <!-- Wording -->
      <b-col md="8">
        <field-input
            name="wording"
            rules="required"
            :model.sync="bankOperationLocal.wording"
        />
      </b-col>

      <!-- Amount -->
      <b-col md="4">
        <field-input
            name="amount"
            rules="required|decimal"
            :model.sync="amount"
            :inputClass="inputClass!=null?inputClass:(amount>0?'text-success':'text-danger')"
        />
      </b-col>

      <!-- Operation date -->
      <b-col md="6">
        <field-date
            name="operationDate"
            :isRequired="true"
            :model.sync="bankOperationLocal.operationDate"
        />
      </b-col>

      <!-- Transaction date -->
      <b-col md="6">
        <field-date
            name="bankTransactionDate"
            :isRequired="true"
            :model.sync="bankOperationLocal.bankTransactionDate"
        />
      </b-col>

      <!-- Transaction method -->
      <b-col md="6">
        <field-select
            :model.sync="bankOperationLocal.paymentMethod"
            name="paymentMethod"
            :options="paymentMethods"
            :isRequired="true"
            label="name"
        />
      </b-col>

      <!-- Bank account -->
      <b-col md="6">
        <field-select
            :model.sync="bankOperationLocal.bankAccount"
            name="bankAccount"
            :options="bankAccounts"
            :isRequired="true"
            label="_display"
        />
      </b-col>

      <!-- Company -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <field-contact
            :name="$tc('company',2)"
            :customerCompanies.sync="bankOperationLocal.companies"
            :isRequired="false"
            :isCompanyOnly="true"
            :multiple="true"
            ref="fieldContact"
        />
      </b-col>

      <!-- Incoming invoices -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <select-incoming-invoice
            :model.sync="bankOperationLocal.incomingInvoices"
            :companies="bankOperationLocal.companies"
            :multiple="true"
            :onlyNotCompletelyPaid="true"
            ref="fieldIncomingInvoice"
        />
        <!--        <select-incoming-invoice-->
        <!--            :model.sync="bankOperationLocal.incomingInvoices"-->
        <!--            :onlyNotCompletelyPaid="true"-->
        <!--        />-->
      </b-col>

      <!-- Outgoing invoices -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <select-outgoing-invoice
            :model.sync="bankOperationLocal.outgoingInvoices"
            :company="bankOperationLocal.company"
            :multiple="true"
            :onlyNotCompletelyPaid="true"
            ref="fieldOutgoingInvoice"
        />

        <!--        <select-outgoing-invoice-->
        <!--            :model.sync="bankOperationLocal.outgoingInvoices"-->
        <!--            :onlyNotCompletelyPaid="true"-->
        <!--        />-->
      </b-col>

      <!-- Salary -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <select-salary
            :model.sync="bankOperationLocal.salary"
            :onlyNotCompletelyPaid="true"
        />
      </b-col>

      <!-- Charges -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <select-charge
            :model.sync="bankOperationLocal.socialSecurityContribution"
            :onlyNotCompletelyPaid="true"
        />
      </b-col>

      <!-- Bank Operation -->
      <b-col
          v-if="showBankAffiliation"
          cols="12"
      >
        <select-bank-operation
            :model.sync="bankOperationLocal.bankOperationConnection"
            :onlyNotCompletelyPaid="true"
            :bank-accounts="bankAccounts"
        />
      </b-col>

      <!-- Comments -->
      <b-col md="12">
        <field-textarea
            name="comment"
            :model.sync="bankOperationLocal.comments"
            :withHTML="false"
        />
      </b-col>


    </b-row>

    <!-- Bank operation form-->
    <b-row
        v-else-if="containerSize=='md'"
        style="border-radius: 10px"
        :style="index%2==0?'background-color: rgb(243,242,247)':'background-color: white'"
    >

      <b-col cols="12">
        <b-row>
          <!-- Operation date -->
          <b-col md="3">
            <div class="d-flex">
              <field-date
                  name="operationDate"
                  :isRequired="true"
                  :model.sync="bankOperationLocal.operationDate"
                  :listIndex="index"
              />

              <icon
                  icon="link"
                  class="mt-2 pt-1 ml-1"
                  :class="isLinked?'text-success':'text-warning'"/>
            </div>
          </b-col>

          <!-- Wording -->
          <b-col md="5">
            <field-input
                name="wording"
                rules="required"
                :model.sync="bankOperationLocal.wording"
                :listIndex="index"
            />
          </b-col>

          <!-- Amount -->
          <b-col md="4"
                 class="d-flex justify-content-between">
            <field-input
                name="amount"
                rules="required|decimal"
                :model.sync="amount"
                :class="amount>0?'text-success':'text-danger'"
                :listIndex="index"
                @change="$emit('update')"
            />

            <div class="mt-2 d-flex justify-content-between"
                 style="max-height: 38px">
              <button-view-more
                  :withText="false"
                  :withIcon="true"
                  :is-open="isViewMoreOpen"
                  class="mt-50 ml-2"
                  @click="isViewMoreOpen=!isViewMoreOpen"/>
              <button-remove
                  :withText="false"
                  :withIcon="true"
                  class="mt-50 ml-2"
                  @click="$emit('removeBankOperation')"/>
            </div>
          </b-col>
        </b-row>


        <b-row v-show="isViewMoreOpen">
          <!-- Transaction date -->
          <b-col md="3">
            <field-date
                name="bankTransactionDate"
                :model.sync="bankOperationLocal.bankTransactionDate"
                :listIndex="index"
            />
          </b-col>

          <!-- Payment Method -->
          <b-col md="3">
            <field-select
                :model.sync="bankOperationLocal.paymentMethod"
                name="paymentMethod"
                :options="paymentMethods"
                :isRequired="true"
                label="name"
                :listIndex="index"
            />
          </b-col>

          <!-- Company -->
          <b-col md="6">
            <field-contact
                :name="$t('company')"
                :customerCompanies.sync="bankOperationLocal.companies"
                :isRequired="false"
                :isCompanyOnly="true"
                :multiple="true"
                ref="fieldContact"
            />
          </b-col>

          <!-- Incoming invoices -->
          <b-col cols="6">
            <!--            <select-incoming-invoice-->
            <!--                :model.sync="bankOperationLocal.incomingInvoices"-->
            <!--                :onlyNotCompletelyPaid="true"-->
            <!--            />-->
            <select-incoming-invoice
                :model.sync="bankOperationLocal.incomingInvoices"
                :companies="bankOperationLocal.companies"
                :multiple="true"
                :onlyNotCompletelyPaid="true"
                ref="fieldIncomingInvoice"
            />
          </b-col>

          <!-- Outgoing invoices -->
          <b-col cols="6">
            <select-outgoing-invoice
                :model.sync="bankOperationLocal.outgoingInvoices"
                :company="bankOperationLocal.company"
                :multiple="true"
                :onlyNotCompletelyPaid="true"
                ref="fieldOutgoingInvoice"
            />
          </b-col>

          <!-- Salary -->
          <b-col cols="6">
            <select-salary
                :model.sync="bankOperationLocal.salary"
                :onlyNotCompletelyPaid="true"
            />
          </b-col>

          <!-- Charges -->
          <b-col cols="6">
            <!--            />-->
            <select-charge
                :model.sync="bankOperationLocal.socialSecurityContribution"
                :onlyNotCompletelyPaid="true"
            />
          </b-col>

          <!-- Bank operation connection -->
          <b-col cols="6">
            <select-bank-operation
                :model.sync="bankOperationLocal.bankOperationConnection"
                :onlyNotCompletelyPaid="true"
                :bank-accounts="bankAccounts"
            />
          </b-col>

          <!-- Comments -->
          <b-col cols="6">
            <field-textarea
                name="comment"
                :model.sync="bankOperationLocal.comments"
                :withHTML="false"
            />
          </b-col>


        </b-row>


      </b-col>

    </b-row>

    <hr>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '../../utils/filter'
import { getObjectId } from '../../utils/utils'

import FieldInput from '@/components/input/Input'
import FieldDate from '@/components/input/Date'
import EmployeeSelect from '../../components/input/Employee'
import FieldSelect from '../../components/input/Select'
import FieldContact from '../input/Contact'
import FieldTextarea from '@/components/input/Textarea'
import ButtonRemove from '@/components/button/Remove'
import ButtonViewMore from '../button/ViewMore'
import useAPI from '../../utils/useAPI'
import store from '@/store'
import moment from 'moment'
import vSelect from 'vue-select'
import SelectIncomingInvoice from '../input/custom/SelectIncomingInvoice'
import SelectOutgoingInvoice from '../input/custom/SelectOutgoingInvoice'
import SelectSalary from '../input/custom/SelectSalary'
import SelectCharge from '../input/custom/SelectCharge'
import SelectBankOperation from '../input/custom/SelectBankOperation'

export default {
  components: {
    FieldInput,
    FieldDate,
    EmployeeSelect,
    FieldSelect,
    FieldContact,
    FieldTextarea,
    ButtonRemove,
    ButtonViewMore,
    moment,
    vSelect,
    SelectIncomingInvoice,
    SelectOutgoingInvoice,
    SelectSalary,
    SelectCharge,
    SelectBankOperation
  },
  props: {
    bankOperation: {
      type: Object,
      default: () => {}
    },
    containerSize: {
      type: String,
      default: 'sm'
    },
    index: {
      type: Number,
      default: 0
    },
    showBankAffiliation: {
      type: Boolean,
      default: false
    },
    sumOwed: {
      default: null
    },
    formErrors: {
      type: Object,
      default: () => {}
    },
    absoluteDisplay: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.bankOperation)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const bankOperationLocal = ref(props.bankOperation)
    const amount = ref(null)
    const isViewMoreOpen = ref(false)
    const fieldContact = ref(null)
    const fieldIncomingInvoice = ref(null)
    const fieldOutgoingInvoice = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { bankAccounts, paymentMethods, salaries, socialSecurityContributions } = useAPI()

    const incomingInvoicesNotCompletelyPaid = computed(() => {
      // console.log(bankOperationLocal.value.company)
      if (bankOperationLocal.value.company != null) {
        // console.log('Company not null')
        let companyId = Number.isInteger(bankOperationLocal.value.company) ? bankOperationLocal.value.company : bankOperationLocal.value.company.id

        let output = store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid'].filter(ii => {
          return ii.supplierCompany != null && ii.supplierCompany.id == companyId
        })
        // console.log(output)
        //
        // let incomingInvoicesNotCompletelyPaid = JSON.parse(JSON.stringify(store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']))
        // console.log(companyId)
        // console.log(incomingInvoicesNotCompletelyPaid.filter(ii => {
        //   // console.log(ii.supplierCompany)
        //   console.log(ii.supplierCompany != null?ii.supplierCompany.id:'-', companyId)
        //   console.log(ii.supplierCompany != null && ii.supplierCompany.id == companyId)
        //   return ii.supplierCompany != null && ii.supplierCompany.id == companyId
        // }))

        return store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid'].filter(ii => {
          return ii.supplierCompany != null && ii.supplierCompany.id == companyId
        })
      } else {
        return store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']
      }

    })

    const outgoingInvoicesNotCompletelyPaid = computed(() => {
      if (bankOperationLocal.value.company != null) {
        let companyId = Number.isInteger(bankOperationLocal.value.company) ? bankOperationLocal.value.company : bankOperationLocal.value.company.id

        return store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid'].filter(oi => {
          // console.log(oi)
          return oi.customerCompany != null && oi.customerCompany.id == companyId
        })
      } else {
        return store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid']
      }
    })

    const inputClass = computed(() => {
      if ('_incomingInvoice' in bankOperationLocal.value) {
        return 'text-danger'
      } else if ('_outgoingInvoice' in bankOperationLocal.value) {
        return 'text-success'
      } else {
        return null
      }
      // if (bankOperationLocal.value._incomingInvoice != null) {
      //   return 'text-danger'
      // } else if (bankOperationLocal.value._outgoingInvoice != null) {
      //   return 'text-success'
      // } else {
      //   return null
      // }
    })

    const isLinked = computed(() => {
      if (
          (
              bankOperationLocal.value.incomingInvoices != null &&
              bankOperationLocal.value.incomingInvoices.length != 0
          ) ||
          (
              bankOperationLocal.value.outgoingInvoices != null &&
              bankOperationLocal.value.outgoingInvoices.length != 0
          ) ||
          bankOperationLocal.value.salary != null ||
          bankOperationLocal.value.socialSecurityContribution != null ||
          bankOperationLocal.value.bankOperationConnection != null
      ) {
        return true
      } else {
        return false
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    watch(() => bankOperationLocal.value, val => {
          // console.log(val)
          // fieldIncomingInvoice.value.companyUpdated(val.value.company)

          if (val.companies) {
            fieldIncomingInvoice.value.companiesUpdated(val.companies)
          }

          if (val.company) {
            fieldOutgoingInvoice.value.companyUpdated(val.company)
          }

          if (
              'incomingInvoices' in val ||
              'outgoingInvoices' in val
          ) {

            if (val.incomingInvoices != null && val.incomingInvoices.length != 0) {
              let ii = store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid'].find(ii => ii.id == getObjectId(val.incomingInvoices[0]))
              fieldContact.value.updateContact(ii.supplierCompany, null)
            } else if (val.outgoingInvoices != null && val.outgoingInvoices.length != 0) {
              let oi = store.getters['outgoingInvoice/outgoingInvoicesNotCompletelyPaid'].find(oi => oi.id == getObjectId(val.outgoingInvoices[0]))
              fieldContact.value.updateContact(oi.customerCompany, null)
            }
          }
        },
        { deep: true }
    )

    watch(amount, val => {
      if (props.absoluteDisplay) {
        if ('_incomingInvoice' in bankOperationLocal.value) {
          bankOperationLocal.value.debit = parseFloat(val)
          bankOperationLocal.value.credit = null
          bankOperationLocal.value._balance = parseFloat(val)
        } else if ('_outgoingInvoice' in bankOperationLocal.value) {
          bankOperationLocal.value.debit = null
          bankOperationLocal.value.credit = parseFloat(val)
          bankOperationLocal.value._balance = parseFloat(val)
        }

      } else {
        if (val >= 0) {
          bankOperationLocal.value.debit = null
          bankOperationLocal.value.credit = parseFloat(val)
          bankOperationLocal.value._balance = parseFloat(val)
        } else {
          bankOperationLocal.value.debit = -parseFloat(val)
          bankOperationLocal.value.credit = null
          bankOperationLocal.value._balance = parseFloat(val)
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filterIncomingInvoice = (options, search) => {
      console.log(options)
      console.log(search)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.sumOwed != null) {
      amount.value = props.sumOwed
    } else {
      if (props.bankOperation.debit == null || props.bankOperation.credit == null) {
        if (props.bankOperation.debit != null) {
          // amount.value = props.absoluteDisplay ? props.bankOperation.debit : props.bankOperation.debit
          amount.value = props.absoluteDisplay ? props.bankOperation.debit : -props.bankOperation.debit
        } else if (props.bankOperation.credit != null) {
          amount.value = props.bankOperation.credit
        }
      } else {
        // amount.value = props.bankOperation.credit
        if (props.bankOperation.credit != 0) {
          amount.value = props.bankOperation.credit
        } else {
          amount.value = -props.bankOperation.debit
          // amount.value = -props.bankOperation.debit
        }
      }
    }

    return {
      // Components
      currency,

      // Data
      bankOperationLocal,
      amount,
      isViewMoreOpen,
      fieldContact,
      fieldIncomingInvoice,
      fieldOutgoingInvoice,

      // Computed
      paymentMethods,
      bankAccounts,
      salaries,
      socialSecurityContributions,
      incomingInvoicesNotCompletelyPaid,
      outgoingInvoicesNotCompletelyPaid,
      inputClass,
      isLinked,
      // bankOperationConnections,

      // Methods
      filterIncomingInvoice,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      for (const [field, errorArrray] of Object.entries(val)) {
        if (
            field.slice(-1).toString() == (this.index + 1).toString() &&
            errorArrray.length > 0
        ) {
          this.isViewMoreOpen = true
        }
      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>