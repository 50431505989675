<template>
  <div>
    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100"
    >
      <v-select
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="list"
          :reduce="label => label.id"
          :placeholder="$tc('ChooseExpense')"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          :append-to-body="appendToBody"
          :filter="filterIncomingInvoice"
          class="w-100"
      >
        <template v-slot:option="option">
          <div class="d-flex">
            <div class="w-100 text-truncate">
              {{ option.billNumber != null ? option.billNumber : '-' }}
            </div>
            <div class="text-right text-danger">
              {{ currency(option.totalAmount) }}
            </div>
          </div>
          <div class="d-flex font-italic">
            <div>
              <small>{{ option.billingDate|moment('L') }}</small>
            </div>
            <div class="w-100 text-right text-truncate">
              <small v-if="option.supplierCompany != null">{{ option.supplierCompany._display }}</small>
              <span v-else>-</span>
            </div>
          </div>
        </template>

      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { comparableString, getObjectId } from '../../../utils/utils'

import vSelect from 'vue-select'
import store from '../../../store'
import moment from 'moment'

export default {
  components: {
    vSelect
  },
  props: {
    model: {
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    onlyNotCompletelyPaid: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    company: {
      type: Object
    },
    companies: {
      type: Array
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const localCompany = ref(props.company)
    const localCompanies = ref(props.companies)
    const name = ref('expenses')
    const label = ref('_display')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const companyId = computed(() => {
      return getObjectId(localCompany.value)
    })

    const companiesIds = computed(() => {
      let output = []

      if (typeof localCompanies.value !== 'undefined') {
        localCompanies.value.forEach(c => {
          output.push(getObjectId(c))
        })
      }
      return output
    })

    // const list = computed(() => {
    //   if (props.onlyNotCompletelyPaid) {
    //     if (companyId.value != 0) {
    //       return store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid'].filter(ii => {
    //         return ii.supplierCompany != null && ii.supplierCompany.id == companyId.value
    //       })
    //     } else {
    //       return store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']
    //     }
    //   } else {
    //     if (companyId.value != 0) {
    //       return store.getters['incomingInvoice/getIncomingInvoices'].filter(ii => {
    //         return ii.supplierCompany != null && ii.supplierCompany.id == companyId.value
    //       })
    //     } else {
    //       return store.getters['incomingInvoice/getIncomingInvoices']
    //     }
    //   }
    // })

    const list = computed(() => {
      // console.log('props.onlyNotCompletelyPaid', props.onlyNotCompletelyPaid)
      // console.log('companiesIds.value', companiesIds.value)

      let output = []
      if (props.onlyNotCompletelyPaid) {

        if (companiesIds.value.length) {
          companiesIds.value.forEach(c => {
            if (c != 0) {
              // console.log('A')
              output = output.concat(
                  store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid'].filter(ii => {
                    return ii.supplierCompany != null && ii.supplierCompany.id == c
                  })
              )
            } else {
              // console.log('B')
              output = output.concat(
                  store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']
              )
            }
          })
        } else {
          // console.log('BB')
          output = output.concat(
              store.getters['incomingInvoice/incomingInvoicesNotCompletelyPaid']
          )
        }

      } else {
        companiesIds.value.forEach(c => {
          if (c != 0) {
            // console.log('C')
            output = output.concat(
                store.getters['incomingInvoice/getIncomingInvoices'].filter(ii => {
                  return ii.supplierCompany != null && ii.supplierCompany.id == c
                })
            )
          } else {
            // console.log('D')
            output = output.concat(
                store.getters['incomingInvoice/getIncomingInvoices']
            )
          }
        })
      }

      // console.log(output)
      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const filterIncomingInvoice = (options, search) => {
      return options.filter(ii => {
        return (
            compare(comparableString(ii.billNumber), comparableString(search)) ||
            compare(comparableString(ii.totalAmount), comparableString(search)) ||
            compare(comparableString(moment(ii.billingDate).format('L')), comparableString(search)) ||
            (
                ii.supplierCompany != null &&
                compare(comparableString(ii.supplierCompany._display), comparableString(search))
            )
        )
      })
    }

    const compare = (label, search) => {
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    }

    const companyUpdated = (newCompany) => {
      localCompany.value = newCompany
    }

    const companiesUpdated = (companies) => {
      // console.log('companiesUpdated', companies)
      localCompanies.value = companies
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      localModel,
      name,
      label,

      // Computed
      list,

      // Methods
      filterIncomingInvoice,
      companyUpdated,
      companiesUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>